import React, { Component } from "react";
import styled from "styled-components";
import BorderedImg from "../imgs/BorderedImg";
import Project from "./Project";
import projects from "../../data/projects"; // Import centralised project data
import { LinkItem } from '../styled/navigation'
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
	border-top:solid 2px ${props => props.theme.red};
  button {
    margin: 0 10px;
    padding: 10px 20px;
    color:${props => props.theme.red};
		background-color:${props => props.theme.black};
    border: none;
    cursor: pointer;
		font-weight:700;
		text-transform:uppercase;
		transition:${props => props.theme.trans};
    &:hover{
			color:${props => props.theme.grey};
			background-color:${props => props.theme.black};
		}
		&.active{
			color:${props => props.theme.black};
			background-color:${props => props.theme.red};
		}
  }
`;

const Item = styled.div`
  margin: 20px;
  width: 290px;
  text-align: center;

  @media only screen and (${(props) => props.theme.mobileThreshold}) {
    width: 310px;
  }

  h3 {
    margin: 2px 0;
  }

  a {
    color: ${(props) => props.theme.primary};
    text-decoration: none;
    font-weight: bold;
		transition:${props => props.theme.trans};
		&:hover{
			color:${props => props.theme.red};
		}
  }
`;

class ProjectGrid extends Component {
  constructor(props) {
    super(props);

		const params = new URLSearchParams(typeof window !== "undefined" ? window.location.search : "");
    const activeCategory = params.get("category") || "New Builds";

    this.state = {
      activeCategory,
      count: 0,
      isProjectOpen: false,
      currLeftOffset: 0,
      currTopOffset: 0,
      currHeight: 0,
      currWidth: 0,
      pid: null,
    };

    this.projectRefs = [];
    this.int = null; // Keep track of the interval
  }

  startAnimationInterval() {
    // Clear any existing interval
    if (this.int) {
      clearInterval(this.int);
    }

    // Restart the animation interval
    this.int = setInterval(() => {
      this.setState(
        (prevState) => ({
          count: prevState.count + 1,
        }),
        () => {
          // Stop the interval when the count reaches the total number of projects
          const totalProjects = this.getFilteredProjects().length;
          if (this.state.count >= totalProjects + 5) {
            clearInterval(this.int);
          }
        }
      );
    }, 180);
  }

  componentDidMount() {
    this.startAnimationInterval();
  }

  componentWillUnmount() {
    if (this.int) {
      clearInterval(this.int);
    }
  }

  onImageSelected(ref, project) {
    this.setState({
      currLeftOffset: ref.current.offsetLeft,
      currTopOffset: ref.current.offsetTop,
      currWidth: ref.current.offsetWidth,
      currHeight: ref.current.offsetHeight,
      isProjectOpen: true,
      pid: project.id,
    });
  }

  toggleHandler = () => {
    this.setState((prevState) => ({
      isProjectOpen: !prevState.isProjectOpen,
    }));
  };

  getFilteredProjects() {
    const { activeCategory } = this.state;
    return projects.filter((project) => project.category === activeCategory);
  }

  handleTabChange = (category) => {
    this.setState({ activeCategory: category, count: 0 }, () => {
      this.startAnimationInterval(); // Restart animations when the tab changes
    });
  };

  render() {
    const filteredProjects = this.getFilteredProjects();
    const status = this.state.count - 5;

    return (
      <>
        <Tabs>
          {["New Builds", "Extensions and Conversions", "Computer Generated Images"].map(
            (category) => (
              <button
                key={category}
                className={category === this.state.activeCategory ? "active" : ""}
                onClick={() => this.handleTabChange(category)}
              >
                {category}
              </button>
            )
          )}
        </Tabs>
        <Container>
          {filteredProjects.map((project, index) => {
            const ref = React.createRef();
            this.projectRefs[index] = ref;

            return (
              <Item key={project.id}>
                <BorderedImg
                  ref={ref}
                  imgURL={project.imgURL}
                  pid={project.id}
                  onSelected={(ref) => this.onImageSelected(ref, project)}
                  interact
                  size={status > index ? "full" : "shrink"}
                />
                <h3>{project.title}</h3>
                <a href={`/projects/${project.slug}?category=${encodeURIComponent(this.state.activeCategory)}`}>View Project →</a>
              </Item>
            );
          })}
        </Container>
        {this.state.pid && (
          <Project
            opened={this.state.isProjectOpen}
            leftOffset={this.state.currLeftOffset}
            topOffset={this.state.currTopOffset}
            itemHeight={this.state.currHeight}
            itemWidth={this.state.currWidth}
            toggleHandler={this.toggleHandler}
            pid={this.state.pid}
          />
        )}
      </>
    );
  }
}

export default ProjectGrid;