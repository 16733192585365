import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Carousel } from "react-responsive-carousel";

const DynamicCarousel = ({ pid }) => {
  // Query images dynamically
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        nodes {
          name
          relativeDirectory
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  // // Filter images for the current project
  // const projectImages = data.allFile.nodes.filter(
  //   (node) => node.relativeDirectory === `portfolio/${pid}`
  // );

	// Filter images for the current project folder
  const projectImages = data.allFile.nodes
    .filter((node) => node.relativeDirectory === `portfolio/${pid}`)
		.sort((a, b) => {
      // Extract numbers from filenames (e.g., "P048-03" → 3)
      const numA = parseInt(a.name.match(/\d+/g)?.pop() || "0", 10);
      const numB = parseInt(b.name.match(/\d+/g)?.pop() || "0", 10);

      return numA - numB; // Sort numerically
    });


  if (!projectImages.length) {
    return <p>No images available for this project.</p>;
  }

  return (
    <Carousel
      autoPlay={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      dynamicHeight
    >
      {projectImages.map((image) => (
        <Img
          key={image.name}
          fluid={image.childImageSharp.fluid}
          alt={image.name}
					imgStyle={{
						objectFit: "contain",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}} // Prevents cropping
          style={{ maxHeight: "70vh" }} // Ensures it fits within the screen
        />
      ))}
    </Carousel>
  );
};

export default DynamicCarousel;