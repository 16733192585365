import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { PortfolioTitle } from '../components/titles/Title'
import FadeIn from "../components/FadeIn";
import ProjectGrid from "../components/portfolio/ProjectGrid";
const Wrapper = styled.div`
	display:flex;
	width:100%;
	flex:1 0 auto;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		flex-direction:column
	}
`;
const LeftText = styled.div`
	flex:1;
	padding-right:20px;
	padding-bottom:20px;
	@media only screen and (${props => props.theme.mobileThreshold}) {
		width:100%
	}
`;

const Title = styled.h1`
	margin-top:0;
	line-height:1.2;
`;
const HREFLink = styled(Link)`
	color:${props => props.theme.grey};
	text-decoration: underline;
`;
const ProjectsPage = () => (
  <Layout>
    <SEO title="Boxas - Projects" />
    <Wrapper>
			<LeftText>
				<PortfolioTitle />
				<FadeIn duration="900" delay="1000">
					<p>Since starting my own practice in February 2020, I have a wide-ranging portfolio built to date, with many more future projects either on-site or waiting to commence.  These include bespoke one-off new build dwellings, several small housing estates for self-builders and house builders, conversions, extensions, refurbishments, loft conversions and upcycled shipping containers to provide holiday cottages.</p>
 					<p>I would love for you to help contribute to the continued success of the practice by appointing me for your project. Please contact me for a free no obligation consultation and we will discuss your project in further detail. </p>
					<p>Please visit the <HREFLink to="/testimonials">testimonial</HREFLink> section for what some of the clients I have worked with had to say.</p>
					<ProjectGrid />
				</FadeIn>
				
			</LeftText>

			
		</Wrapper>
  </Layout>
)

export default ProjectsPage
